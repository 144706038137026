.root {
  width: 32px;
  height: 32px;
  border-radius: 25em;
  overflow: hidden;
}

.avatar {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.memberInitials {
  background-color: #dfe1e6;
  border-radius: 25em;
  color: #172b4d;

  display: block;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  left: 0;
  line-height: 32px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.memberInitials:hover {
  background-color: #c1c7d0;
}
