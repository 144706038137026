.root {
  margin: 0;
  padding: 0;
  list-style: none;
}

.action {
  text-decoration: none;
  display: block;
  border-bottom: 1px solid rgba(9, 30, 66, 0.13);
}
.action:hover {
  background-color: rgba(9, 30, 66, 0.13);
  border-radius: 4px;
  border-bottom-color: transparent;
}
.action:active {
  background-color: rgba(9, 30, 66, 0.25);
  border-radius: 4px;
  border-bottom-color: transparent;
}
