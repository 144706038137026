.root {
  min-height: 32px;
  padding: 12px 8px 12px 48px;
  position: relative;

  line-height: 20px;
  color: var(--ds-text);
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

.creator {
  left: 8px;
  top: 12px;
  position: absolute;
}

.date {
  color: var(--ds-text-subtle);
  font-size: 12px;
}

.operations {
  margin: 0.6em 1em 0.3em 0;
  display: flex;
  flex-direction: row;
}
.operations > * {
  margin-top: 0;
  margin-bottom: 0;
}
